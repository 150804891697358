
import { ref } from 'vue';
import { defineComponent } from 'vue';
import { Form, Field, ErrorMessage } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiMixin';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import moment from 'moment';
import Multiselect from '@vueform/multiselect';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'aduit-rail-list',
  components: {
    Form,
    Field,
    Multiselect,
    Datatable,
  },
  data() {
    return {
      api_url: '',
      moment: '' as any,
      loading: false,
      addPanel: false,
      tableHeader: [
        {
          name: 'Actions',
          key: 'actions',
          sortable: false,
        },
        {
          name: 'Entity Type',
          key: 'entity_type',
          sortable: true,
        },
        {
          name: 'Audit Events',
          key: 'events',
          sortable: true,
        },
        {
          name: 'Responsible User',
          key: 'user_id',
          sortable: true,
        },
      ],
      tableData: [] as any,
      employeeList: [] as any,
      entityList: [] as any,
      Entity: [] as any,
      user_id: '',
      componentKey: 0,
      batch: {
        entity_type: '',
      },
      showCourseNotice: false,
      button: false,
      editPanel: false,
      addrole: false,
      showTreeEntity: false,
    };
  },
  async created() {
    this.moment = moment;
    this.api_url = this.VUE_APP_API_URL;
    await this.getEmployeeList();
    await this.EntityTypeList();

  },
  methods: {
    async search() {
      this.loading = true;
      ApiService.get(
        'auditrail/view?entity_type=' +
          this.entityList +
          '&user_id=' +
          this.user_id
      )
        .then((response) => {
          this.loading = false;
          this.tableData = response.data.data;
          this.showCourseNotice = true;
          this.componentKey += 1;
          console.log(response);
        })
        .catch((response) => {
          this.loading = false;
        });
    },
    async getEmployeeList() {
      ApiService.get('configurations/employee/list')
        .then((response) => {
          this.employeeList = response.data.data;
          console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async EntityTypeList() {
      ApiService.get('configurations/employee/entityTypeList')
        .then((response) => {
          this.entityList = response.data.data;
          this.entityList.forEach((item, index) => {
            let obj = {
              value: item.model_name,
              label: item.model_name,
            };
            this.Entity.push(obj);
          });
          // console.log(response);
        })
        .catch((response) => {
          console.log(response);
        });
    },
    view(data) {
      this.emitter.emit('audit-rail-view', data);
    },
  },
  setup() {},
});
